<template>
  <div>
    <v-form ref="form" :id="formId" v-model="formIsValid" @submit.prevent="submit">
      <v-jsf v-model="formData.model" :schema="schema" :options="newOptions">
        <!-- signature field: receives string with URL or bse64 image-->
        <template slot="custom-signature" slot-scope="context">
          <v-jsf-signature v-bind="context"></v-jsf-signature>
        </template>
        <!-- wysiwyg Editor: receives string with html-->
        <template slot="custom-editor" slot-scope="context">
          <v-jsf-editor v-bind="context"></v-jsf-editor>
        </template>
        <!-- birth date picker -->
        <template slot="custom-birth-date" slot-scope="context">
          <v-jsf-birth-date v-bind="context"></v-jsf-birth-date>
        </template>
        <!-- html5 date picker -->
        <template slot="custom-date-picker" slot-scope="context">
          <v-jsf-date-picker v-bind="context"></v-jsf-date-picker>
        </template>
      </v-jsf>
    </v-form>
  </div>
</template>

<script>
export default {
  components: {
    VJsfSignature: () => import("@/components/form-vjsf/VjsfSignature"),
    VJsfEditor: () => import("@/components/form-vjsf/VjsfEditor"),
    VJsfBirthDate: () => import("@/components/form-vjsf/VjsfBirthDate"),
    VJsfDatePicker: () => import("@/components/form-vjsf/VjsfDatePicker"),
  },
  props: {
    formId: { type: String, required: true },
    formData: { type: Object, required: true },
    formFieldsOptions: {
      type: Object,
      default: () => ({
        //See VSJF docs: https://koumoul-dev.github.io/vuetify-jsonschema-form/latest/configuration
        fieldColProps: { cols: 12, md: 4, sm: 6 },
      }),
    },
    fieldOptions: {
      type: Object,
      default: () => ({}),
      // overwrite example
      // tipo_puerta_id: {
      //   fieldColProps: {
      //     cols: 12,
      //   },
      // },
    },
  },
  data: () => ({
    formIsValid: null,
  }),
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.formIsValid) this.$emit("submitForm");
    },
    formatProperty(property) {
      const {
        "x-display": xDisplay = "",
        "x-options": xOptions = {},
        "x-class": xClass = "",
      } = property;
      const fullWidthDisplay = ["textarea", "custom-editor", "tabs"];
      const isFullWidth = fullWidthDisplay.includes(xDisplay);
      const newFieldOptions = JSON.parse(JSON.stringify(this.formFieldsOptions));
      const subProperties = !!property.properties;

      if (isFullWidth || subProperties) {
        delete newFieldOptions.fieldColProps.sm;
        delete newFieldOptions.fieldColProps.md;
      }

      property["x-options"] = { ...xOptions, ...newFieldOptions };
      property["x-class"] = property["x-class"] ? xClass + " px-2" : "px-2";

      if (property.properties) {
        for (let subPropertyKey in property.properties) {
          const subProperty = property.properties[subPropertyKey];
          this.formatProperty(subProperty, true);
        }
      }
    },
  },
  beforeMount() {
    const formDataIsPreLoaded = !!Object.keys(this.formData?.schema).length;
    if (!formDataIsPreLoaded) this.$emit("getFormData");
  },
  computed: {
    newOptions() {
      const options = {
        locale: "es",
        datePickerProps: {
          "first-day-of-week": 1,
          "no-title": true,
        },
        messages: {
          required: "Campo obligatorio",
          noData: "No coincide ningún valor",
          search: "Buscar..",
          minimum: "El valor tiene que ser más grande o igual que {minimum}",
          exclusiveMinimum: "El valor tiene que ser más grande que {exclusiveMinimum}",
          maximum: "El valor tiene que ser menor o igual que {maximum}",
          exclusiveMaximum: "El valor tiene que ser menor que {exclusiveMaximum}",
          minLength: "{minLength} caracteres, mínimo",
          maxLength: "{maxLength} caracteres, máximo",
          minItems: "Mínimo,  {minItems} elementos",
          maxItems: "Maximo, {maxItems} elementos",
          pattern: "El formato no es correcto",
        },
      };
      return { ...options, ...this.formData.options };
    },
    schema() {
      const formattedSchema = JSON.parse(JSON.stringify(this.formData.schema || {}));

      if (formattedSchema.properties) {
        for (let propertyKey in formattedSchema.properties) {
          this.formatProperty(formattedSchema.properties[propertyKey]);
        }
      }
      return formattedSchema;
    },
  },
  watch: {
    key() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped></style>
